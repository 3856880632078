import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form, Button,Navbar, Nav, } from 'react-bootstrap';
import { auth } from '../firebase.config'; // Firebase configuration
import { RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, signInWithCredential } from 'firebase/auth'; // Firebase Auth imports
import { useNavigate } from 'react-router-dom'; // For navigation
import '../CSS/SignUp.css';
import Header from './Header'; // Import the new Header component
import axios from 'axios';
import Footer from './Footer';
import logoimage from '../assets/DF-LOGOb1.png';
import Illustration from '../assets/Illustration.png'


export default function SignUp() {
  const [showForm, setShowForm] = useState(false);
  const [hideImage, setHideImage] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+91'); // Default to India
  const [otp, setOtp] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [error, setError] = useState('');
  const [otpSent, setOtpSent] = useState(false); // Initial state is false
  const otpRef = useRef(null); // Ref for OTP input
  const navigate = useNavigate();

  useEffect(() => {
    if (window.innerWidth <= 576) {
      const timer = setTimeout(() => {
        setHideImage(true);
        setShowForm(true);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    if (otp.length === 6 && verificationId) {
      verifyOtp();
    }
  }, [otp]);

  useEffect(() => {
    if (phoneNumber.length === 10 && !otpSent) { // Ensure OTP is sent only once
      sendOtp();
      if (otpRef.current) {
        otpRef.current.focus(); // Focus OTP field when OTP is sent
      }
    }
  }, [phoneNumber]);

  const setupRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response) => {
            console.log('reCAPTCHA solved', response);
          },
        },
        auth
      );
    } else {
      console.log('reCAPTCHA already initialized');
    }
  };

  const sendOtp = async () => {
    setupRecaptcha();

    const appVerifier = window.recaptchaVerifier;

    try {
      const confirmationResult = await signInWithPhoneNumber(auth, `${countryCode}${phoneNumber}`, appVerifier);
      setVerificationId(confirmationResult.verificationId);
      setOtpSent(true); // Update state after sending OTP
      setError('');
    } catch (err) {
      console.error('Error sending OTP:', err);
      setError('Failed to send OTP. Try again later.');
      window.recaptchaVerifier.reset(); // Reset recaptcha on failure
    }
  };

  const verifyOtp = async () => {
    if (!otp || !verificationId) {
      setError('Please enter the OTP.');
      return;
    }

    try {
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      await signInWithCredential(auth, credential);
      setError('OTP verified successfully.');
      // Navigate to payment page or any other page on success
      // navigate('/pay');
    } catch (err) {
      console.error('Error verifying OTP:', err);
      setError('Invalid OTP. Please enter the correct OTP.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const memberId = e.target.memberId.value;
    const email = e.target.email.value;
    const dob = e.target.dob.value;
    const address = e.target.address.value;
    const phoneNumber = e.target.phoneNumber.value.replace(/\D/g, ''); // Remove non-digit characters
  
    try {
      const response = await axios.post('https://discountfeast.co.in/newmember/register', { name, memberId, email, dob, address, phoneNumber });
      console.log(response.data);
      // Navigate to the /offercards page after successful registration
      navigate('/offercards');
    } catch (err) {
      console.error('Error registering member:', err);
      setError('Failed to register member. Please try again.');
    }
  };
  

  return (
    <>
          <Row className='bgheader'>
          <Navbar expand="md" className="py-0  px-5">
      <Container fluid>
        {/* Logo */}
        <Navbar.Brand href="/">
          <img src={logoimage} alt="Logo1" style={{ maxHeight: '80px' }} />
        </Navbar.Brand>

       
        {/* Navbar links and buttons */}
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <p className="mb-0">
                  Already a member? <a href="/signupform">Sign In</a>
                </p>
        </Navbar.Collapse>
      </Container>
    </Navbar>
      </Row>
      <Container className="d-grid align-items-center p-0 w-100" style={{ maxWidth: '100%' }}>
        <Row className="w-100" style={{ marginLeft: '0px' }}>
          {/* Left Column with Image */}
          <Col lg={4} md={4} sm={4} xs={12} className={`p-0 dineinimage-wrapper ${hideImage ? 'hide' : ''}`}>
            <div className="dineinimage"></div>
          </Col>

          {/* Right Column with Form */}
          <Col lg={8} md={8} sm={8} xs={12} className={`form align-items-center bgimage p-3 ${showForm ? 'show' : ''}`}>
            <div className=" w-100">
              {/* Form Section */}
              <div className="form-container pt-4 " >
                <h2 className="signuph2 text-center">Sign up to Discount Feast</h2>

                <Form onSubmit={handleSubmit}>
                  {/* Form fields */}
                  <Row>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <Form.Group controlId="name" className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="name"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <Form.Group controlId="memberId" className="mb-3">
                        <Form.Label>Member ID</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Member ID"
                          name="memberId"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <Form.Group controlId="email" className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          name="email"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <Form.Group controlId="dob" className="mb-3">
                        <Form.Label>Date of Birth</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="MM / DD / YYYY"
                          name="dob"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} sm={12} md={12} xs={12}>
                      <Form.Group controlId="address" className="mb-3">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          placeholder="Address"
                          name="address"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} sm={8} md={8} xs={12}>
                      <Form.Group controlId="phoneNumber" className="mb-3">
                        <Form.Label>Phone Number</Form.Label>
                        <div>
                          <Form.Control
                            type="text"
                            placeholder="1234567890"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                            name="phoneNumber"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    {/* OTP Section - Initially displayed and enabled/disabled based on `otpSent` state */}

                    <Col lg={6} sm={12} md={12} xs={12}>
                      <Form.Group controlId="otp" className="mb-3">
                        <Form.Label>Enter OTP</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          maxLength={6}
                          required
                          ref={otpRef} // Attach ref to OTP input
                          disabled={!otpSent} // Disable OTP field if OTP not sent
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} sm={12} md={12} xs={12}>
                      <Form>
                        {['checkbox'].map((type) => (
                          <div key={`default-${type}`} className="mb-3">
                            <Form.Check // prettier-ignore
                              type={type}
                              id={`default-${type}`}
                              label={<span>
                                Creating an account means you’re okay
                                with our <a href="" style={{ textDecoration: 'none' }}>Terms of Service, Privacy Policy,</a>
                                and our default <a href="" style={{ textDecoration: 'none' }}>Notification Settings.</a>
                              </span>}
                            />
                          </div>
                        ))}
                      </Form>
                    </Col>
                    <Col lg={6} sm={12} md={12} xs={12}>
                      <Form>
                        {['checkbox'].map((type) => (
                          <div key={`default-${type}`} className="mb-3">
                            <Form.Check // prettier-ignore
                              type={type}
                              id={`default-${type}`}
                              label={<span>
                                I would like to receive communication
                                & updates on <a href="" style={{ textDecoration: 'none' }}>WhatsApp </a> with the number I have provided.
                              </span>}
                            />
                          </div>
                        ))}
                      </Form>
                    </Col>
                  </Row>

                  <div id="recaptcha-container" className="mb-3"></div>
                  {error && <p className="text-danger">{error}</p>}
                  <Row>
                    <Col lg={4} sm={12} md={12} xs={12}>
                      <Button type="submit" className="btn-warning w-100">
                        Register
                      </Button>
                    </Col>
                    <Col lg={4} sm={12} md={12} xs={12}>
                      <p className='d-flex'>Already a member? <a href="/signupform" style={{ textDecoration: 'none' }} onClick={() => navigate('/signin')}>Sign In</a>
                        <p style={{ paddingLeft: '8px' }}>Or</p>
                      </p>
                    </Col>
                    <Col lg={4} sm={12} md={12} xs={12}>
                      <p onClick={() => navigate('/requestForMembership')} style={{ cursor: 'pointer' }}>
                        Request For <a href="" style={{ textDecoration: 'none' }}>Membership </a>
                      </p>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
        
      </Container>
      <Row>
        <Col lg={12}>
          <Footer />
        </Col>
      </Row>
    </>
  );
}
