import React, { useEffect, useState } from 'react';
import '../CSS/Offercards.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Button, Col, Container, Row, Modal, Form } from 'react-bootstrap';
import imglogo from '../assets/Untitled design (57) 1.png';
import { FaShoppingCart } from "react-icons/fa";
import axios from 'axios';
import Horizontal from '../assets/Vector 2.png';
import QRsacn from '../assets/qr_img 1.png';
import { auth } from '../firebase.config';
import { QRCodeCanvas } from 'qrcode.react';
import middleimg from '../assets/WhatsApp Image 2024-09-16 at 6.48.53 PM.jpeg'
import logoimage from '../assets/DF-LOGO1 (2).png';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
// import { FaShoppingCart } from 'react-icons/fa';


export default function Offercards() {
  const [coupons, setCoupons] = useState([]);
  const [selectedHotelCoupons, setSelectedHotelCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [noOffers, setNoOffers] = useState(false);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [message, setMessage] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const navigate = useNavigate();

  // Fetch all available coupons
  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await axios.get('https://discountfeast.co.in/showcoupon/coupons');
        setCoupons(response.data);
      } catch (error) {
        console.error('Failed to fetch coupons', error);
      }
    };

    fetchCoupons();
  }, []);

  // Fetch offers for a particular hotel based on hotelId
  const handleOfferClick = async (hotelId) => {
    try {
      const response = await axios.get(`https://discountfeast.co.in/couponredeem/hotel/${hotelId}`);
      if (response.data.length === 0) {
        setNoOffers(true);
      } else {
        setSelectedHotelCoupons(response.data);
        setNoOffers(false);
      }
      setShowModal(true);
    } catch (error) {
      console.error('Failed to fetch coupons for the hotel', error);
      alert('Offers are not available');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedHotelCoupons([]);
    setSelectedCoupon(null);
    setNoOffers(false);
  };

  const handleCouponClick = (coupon) => {
    setSelectedCoupon(coupon);
    setShowDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setShowDetailsModal(false);
    setSelectedCoupon(null);
  };

  // Handle mobile number input
  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };

  // Redeem the selected coupon
  const handleRedeem = async () => {
    setIsRedeeming(true);
    try {
      // Get current user's Firebase token if needed (optional)
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('User not authenticated');
      }

      const firebaseToken = await currentUser.getIdToken(true);

      // Redeem the coupon by sending coupon code and mobile number to the backend
      const response = await axios.post(
        'https://discountfeast.co.in/redeemedCoupons/redeem',
        {
          couponCode: selectedCoupon.couponCode,
          mobileNumber: mobileNumber, // Send mobile number with the request
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseToken}`,
          },
        }
      );

      console.log('Coupon redeemed successfully:', response.data);
      setMessage(`Coupon redeemed successfully for member ID: ${response.data.memberId}`);
      setShowDetailsModal(false);
      setShowModal(false);
    } catch (error) {
      console.error('Error redeeming coupon:', error);
      setMessage('Failed to redeem coupon.');
    } finally {
      setIsRedeeming(false);
    }
  };

  return (
    <>
     
        {/* <Row>
          <Col lg={7} sm={12}>
            <img src={imglogo} alt="Logo" className="img-fluid" />
          </Col>
          <Col lg={5} sm={12}>
            <Navbar expand="lg">
              <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className='' style={{ marginTop: '70px' }}>
                    <Nav.Link href="#home" style={{ padding: '20px' }}>Dine In</Nav.Link>
                    <NavDropdown title="Shopping" id="basic-nav-dropdown" style={{ padding: '10px' }}>
                      <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Profile" id="basic-nav-dropdown" style={{ padding: '10px' }}>
                      <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="#home" style={{ padding: '20px' }}><FaShoppingCart /></Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </Col>
        </Row> */}
        {/* <Row> */}
        
          <Col lg={12}>
        <Navbar expand="md" className="py-0 bg-dark px-5">
      <Container fluid>
        {/* Logo */}
        <Navbar.Brand href="/">
          <img src={logoimage} alt="Logo" style={{ maxHeight: '80px' }} />
        </Navbar.Brand>

        {/* Toggle button for mobile view */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="bg-light" />

        {/* Navbar links and buttons */}
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="me-4">
            <Nav.Link href="#" style={{ color: '#FFFFF3', fontWeight: '500' }}>Dine In</Nav.Link>
            <Nav.Link href="#" style={{ color: '#FFFFF3', fontWeight: '500' }}>Shopping</Nav.Link>
            <Nav.Link href="#" style={{ color: '#FFFFF3', fontWeight: '500' }}>Profile</Nav.Link>
            
          </Nav>

          {/* Auth Buttons */}
          <div className="d-flex align-items-center">
            <Button
              // variant="primary"
              className="mx-2"
              style={{
                fontSize: '14px',
                padding: '8px 15px',
                borderRadius: '20px',
                backgroundColor: '#EF4C0B',
                width: '100px',
                border:'none'
              }}
              onClick={() => navigate('/signin')}
            >
              Register
            </Button>
            <Button
              variant="secondary"
              className="mx-2"
              style={{
                fontSize: '14px',
                padding: '8px 15px',
                borderRadius: '20px',
                width: '100px',
              }}
              onClick={() => navigate('/signupform')}
            >
              Sign In
            </Button>
          </div>
        </Navbar.Collapse>
        
      </Container>
     
    </Navbar>
    </Col>
    {/* </Row> */}
    <Container>
        <Row>
          {/* <h3 className='mt-4' style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>Dine in offers!</h3> */}
        </Row>
        <Row className='' >
          <Col lg={12} className='mt-4' style={{height:'55vh' }} >
          <img src={middleimg} alt="" style={{height:'50vh',width:'100vw',borderRadius:'8px' }} />
          </Col>

        </Row>

       

        {/* <Row className='maincardd ' >
           <div className="custom-coupons-container">
          {coupons.map((coupon) => (
            // <Col lg={3} md={6} sm={12} key={index}>
              <div key={coupon._id} className="custom-coupon">
                <img
                  src={`http://localhost:8080/${coupon.couponImage}`}
                  alt={coupon.hotelName}
                  className="custom-coupon-image"
                />
              
              <div className="custom-coupon-details">
                <h5 >{coupon.hotelName}</h5>
                <Button className='p-1' style={{background:'#6C7594',border:'none'}} onClick={() => handleOfferClick(coupon.hotelId)}>Show Offers</Button>
                <h6 className="custom-location-city">{coupon.location}, {coupon.city}</h6>
               
              </div>
              </div>
            //  </Col>
           
          ))}
           </div>
        </Row> */}
        <Row className='maincardd'>
  {coupons.map((coupon) => (
    <Col lg={3} md={4} sm={6} key={coupon._id} className="mb-4">
      <div className="custom-coupon">
        <img
          src={`https://discountfeast.co.in/${coupon.couponImage}`}
          alt={coupon.hotelName}
          className="custom-coupon-image"
          style={{width:'100%' ,height:'250px'}}
        />
        <div className="custom-coupon-details">
          <h5>{coupon.hotelName}</h5>
          <Button className='p-1' style={{background:'#6C7594',border:'none'}} onClick={() => handleOfferClick(coupon.hotelId)}>Show Offers</Button>
          <h6 className="custom-location-city">{coupon.location}, {coupon.city}</h6>
        </div>
      </div>
    </Col>
  ))}
</Row>
      </Container>

      {/* Modal to display coupon details */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Coupon Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {noOffers ? (
            <p>No offers available for this hotel.</p>
          ) : (
            <div className="row container" >
              {selectedHotelCoupons.map((coupon, index) => (
                <div key={index} className="col-md-12 mb-3" style={{marginLeft:'10px'}}>
                  <div className="cardpop d-flex  flex-row h-100" onClick={() => handleCouponClick(coupon)}>
                    <div className="col-lg-4 p-1">
                      <img
                        src={`https://discountfeast.co.in/${coupon.couponImage}`}
                        alt={coupon.title}
                        className="img-fluid "
                        style={{width:'100px', height:'100px'}}
                      />
                    </div>
                    <div className="card-body col-lg-8 d-flex flex-column justify-content-center">
                      <h1 className="card-text">
                        {coupon.discountValue} {coupon.discountType === 'percentage' ? '%' : 'RS'} OFF
                      </h1>
                      <h6> {coupon.couponCode}</h6>
                      <h6 className="card-title">
      {coupon.discountValue} {coupon.discountType === 'percentage' ? '%' : 'RS'}
      &nbsp; off&nbsp;{coupon.title}
    </h6>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      {/* Modal to show detailed description of the selected coupon */}
     
      {selectedCoupon && (
        <Modal show={showDetailsModal} onHide={handleCloseDetailsModal}>
          <Modal.Header closeButton>
           
          </Modal.Header>
          
          <Modal.Body>
          <Container className=' cardpop '>
            
            <div className="d-flex   flex-row h-100">
              <div className="col-lg-4 p-3">
              <div className=' d-flex justify-content-center'>
                <img
                  src={`https://discountfeast.co.in/${selectedCoupon.couponImage}`}
                  alt={selectedCoupon.title}
                  className="img-fluid"
                  style={{width:'100px', height:'100px'}}
                />
              </div>
              </div>
              
              <div className="col-lg-8 d-flex flex-column  " style={{paddingTop:'10px'}}>
             
              <Modal.Title  >{selectedCoupon.discountValue} {selectedCoupon.discountType === 'percentage' ? '%' : 'RS'} OFF</Modal.Title>
             
                <p>{selectedCoupon.brand}</p>
                <h6> {selectedCoupon.discountValue} {selectedCoupon.discountType === 'percentage' ? '%' : 'RS'}
            &nbsp; off&nbsp;{selectedCoupon.title}</h6>
                
              
            </div >
            </div>
            <div className='pl-5'>
            <h6>CouponCode:<span > {selectedCoupon.couponCode}</span></h6>
            
            {/* Display description as a list */}
           
            <ul className="coupon-description-list" style={{listStyle:'disc'}}>
  {selectedCoupon.description.split('\n').map((point, index) => (
    <li key={index}>{point}</li>
  ))}
</ul>
</div>


            <Row>
              <Col className="d-flex justify-content-center">
                <img src={Horizontal} alt="Horizontal Line" className="horizontal-line" />
              </Col>
            </Row>
            {/* <Row className="d-flex justify-content-center">
              <Col md={6} className="d-flex justify-content-center">
                <img src={QRsacn} alt="QR Code" className="qr-code-img" />
              </Col>
            </Row> */}
            <Row className="d-flex justify-content-center mt-3">
              {/* Generate QR Code */}
              <QRCodeCanvas
                value={`Hotel: ${selectedCoupon.hotelName}, Location: ${selectedCoupon.location}, City: ${selectedCoupon.city}, Coupon Code: ${selectedCoupon.couponCode}`}
                size={120}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                style={{height:'100px'}}
                level={"L"}
              />
            </Row>
            {/* <Row>
              <Col className="d-flex justify-content-center">
                <p className="text-muted">Scan to Redeem</p>
              </Col>
            </Row> */}

            <Row className=" mt-2 pl-5">
              <Col md={8}>
                <Form onSubmit={(e) => {
                  e.preventDefault();
                  handleRedeem();
                }}>
                  <Form.Group controlId="mobileNumber">
                    <Form.Label>Enter your mobile number:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter mobile number"
                      value={mobileNumber}
                      onChange={handleMobileNumberChange}
                      required
                    />
                  </Form.Group>
                  <Button style={{background:'#6C7594',border:'none'}}  variant="primary" type="submit" className="mt-1 p-1" disabled={isRedeeming}>
                    {isRedeeming ? 'Redeeming...' : 'Redeem Now'}
                   
                  </Button>
                </Form>
              </Col>
            </Row>

            {message && <Row className="mt-3">
              <Col className="d-flex justify-content-center">
                <p className="text-danger">{message}</p>
              </Col>
            </Row>}
            </Container>
          </Modal.Body>
         
        </Modal>
      )}
     
     
        
        <Col lg={12} className='mt-5'>
        <Footer/>
        </Col>
       
     
    
    </>
  );
}
