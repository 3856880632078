import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../CSS/Pay.css'; // Assuming this contains the styles
import { IoClose } from "react-icons/io5";

export default function Pay() {
  const [paymentMethod, setPaymentMethod] = useState(''); 
  const [message, setMessage] = useState('');
  const [amount, setAmount] = useState(0);
  const [bankDetails, setBankDetails] = useState([]); 
  const [showPaybox, setShowPaybox] = useState(true); // To control visibility of the paybox
  const navigate = useNavigate(); // Initialize the navigation hook

  useEffect(() => {
    const fetchAmount = async () => {
      try {
        const response = await axios.get('https://discountfeast.co.in/newpay/amount'); 
        setAmount(response.data.amount);
      } catch (error) {
        console.error('Error fetching amount', error);
      }
    };

    fetchAmount();
  }, []);

  useEffect(() => {
    if (paymentMethod === 'bankTransfer') {
      const fetchBankDetails = async () => {
        try {
          const response = await axios.get('https://discountfeast.co.in/bankdetails/getbankdetails'); 
          setBankDetails(response.data);
        } catch (error) {
          console.error('Error fetching bank details', error);
        }
      };

      fetchBankDetails();
    } else {
      setBankDetails([]); 
    }
  }, [paymentMethod]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const paymentDetails = {
      paymentMethod,
      amount,
    };

    try {
      const response = await axios.post('https://discountfeast.co.in/newpay/confirm', paymentDetails); 
      setMessage('Your procedure is completed!');
    } catch (error) {
      setMessage('Error processing payment. Please try again.');
    }
  };

  const handleClose = () => {
    setShowPaybox(false); // Hides the paybox when close is clicked
    navigate('/signupform'); // Redirect to the sign-in page
  };

  if (!showPaybox) {
    return null; // Don't render anything if the paybox is closed
  }

  return (
    <Container>
      <div className="paybox align-item-center justify-content-center ">
       
 {/* Cross Icon for Closing */}
        <Col lg={12} className='d-flex justify-content-end' style={{fontWeight:'bold'}} >
       <IoClose onClick={handleClose} size={20}/>
         </Col>
        <Row className="justify-content-center">
          
          <Col lg={6} className="paycontent">
            <h3>Let’s Make Payment</h3>
            <p style={{ color: '#575757' }}>
              To complete your subscription, please choose an offline payment method.
            </p>

            <form onSubmit={handleSubmit}>
              <Row>
                <p>Select Payment Method</p>
                <select
                  className="form-select"
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                >
                  <option value="" disabled>Select Payment Option</option>
                  <option value="bankTransfer">Bank Transfer</option>
                  <option value="checkPayment">RazorPay</option>
                </select>
              </Row>

              {paymentMethod === 'bankTransfer' && bankDetails.length > 0 && (
                <Row className="mt-3">
                  {bankDetails.map((detail, index) => (
                    <Col key={index} className="d-flex align-items-center mb-3">
                      <Col lg={6} className="d-flex flex-column">
                        <Row className="d-flex flex-row mb-2 align-items-center">
                          <Col lg={5} className="font-weight-bold">Bank Name:</Col>
                          <Col lg={7}>{detail.bankName || 'Fetching...'}</Col>
                        </Row>
                        <Row className="d-flex flex-row mb-2 align-items-center">
                          <Col lg={5} className="font-weight-bold">Payee Name:</Col>
                          <Col lg={7}>{detail.payeeName || 'Fetching...'}</Col>
                        </Row>
                        <Row className="d-flex flex-row mb-2 align-items-center">
                          <Col lg={5} className="font-weight-bold">Account Number:</Col>
                          <Col lg={7}>{detail.accountNumber || 'Fetching...'}</Col>
                        </Row>
                        <Row className="d-flex flex-row mb-2 align-items-center">
                          <Col lg={5} className="font-weight-bold">IFSC Code:</Col>
                          <Col lg={7}>{detail.ifscCode || 'Fetching...'}</Col>
                        </Row>
                        <Row className="d-flex flex-row mb-2 align-items-center">
                          <Col lg={5} className="font-weight-bold">Branch Name:</Col>
                          <Col lg={7}>{detail.branchName || 'Fetching...'}</Col>
                        </Row>
                        <Row className="d-flex flex-row mb-2 align-items-center">
                          <Col lg={5} className="font-weight-bold">Reference Number:</Col>
                          <Col lg={7}>{detail.branchReferenceNumber || 'Fetching...'}</Col>
                        </Row>
                      </Col>
                      <Col lg={6} className="d-flex justify-content-center align-items-center">
                        <img
                          src={`https://discountfeast.co.in/${detail.image}`} 
                          alt={detail.image}
                          className="custom-coupon-image"
                          style={{ width: '200px', height: '200px' }}
                        />
                      </Col>
                    </Col>
                  ))}
                </Row>
              )}

              {/* Show Pay button only for RazorPay */}
              {paymentMethod === 'checkPayment' && (
                <Row className="mt-3">
                  <Col lg={12} className="justify-content-center">
                    <Button
                      type="submit"
                      className="paybutton justify-content-center"
                      style={{ background: '#9563FF', border: 'none' }}
                    >
                      Pay
                    </Button>
                  </Col>
                </Row>
              )}
            </form>

            {message && <p className="mt-3">{message}</p>}
          </Col>

          <Col lg={4} className="payingcontent ">
            <div className="payingbox">
              <h5 style={{ color: '#575757' }}>You’re paying,</h5>
              <h1>₹{amount.toFixed(2)}</h1>
              <Row>
                <Col lg={7} className="mt-3">
                  <p style={{ fontWeight: 'bold' }}>Discount Feast Membership</p>
                </Col>
                <Col lg={5} className="mt-3">
                  <h6 style={{ fontWeight: 'bold' }}>₹ {amount.toFixed(2)}</h6>
                </Col>
              </Row>
              <div className="payline"></div>
              <Row className="mt-3">
                <Col lg={6}><h6 style={{ fontWeight: 'bold' }}>Tax</h6></Col>
                <Col lg={6}><h6 style={{ fontWeight: 'bold' }}>₹ 0.00</h6></Col>
              </Row>
              <Row className="mt-3">
                <Col lg={6}><h6 style={{ fontWeight: 'bold' }}>Total</h6></Col>
                <Col lg={6}><h6 style={{ fontWeight: 'bold' }}>₹ {amount.toFixed(2)}</h6></Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
