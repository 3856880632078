import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';  // Import Axios
import '../CSS/member.css';

import logoimage from '../assets/Background Removed - Discount Feast Logo.png';

export default function RequestForMembership() {
   
    // State for form fields
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        address: '',
    });

    // State for validation errors
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        address: '',
    });

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Validate form data
    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Valid email is required';
        if (!formData.phoneNumber || !/^\+?\d{10,15}$/.test(formData.phoneNumber)) newErrors.phoneNumber = 'Valid phone number is required';
        if (!formData.address) newErrors.address = 'Address is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                const response = await axios.post('https://discountfeast.co.in/requestnew/requestmember', formData);
                console.log('Form submitted successfully:', response.data);
                // Handle success (e.g., show a success message)
               
            } catch (error) {
                console.error('Error submitting form:', error);
                // Handle error (e.g., show an error message)
            }
        }
    };

    return (
        <Container fluid className="align-items-center justify-content-center p-0 w-100" style={{ maxWidth: '100%' }}>
            <Row className="w-100" style={{ marginLeft: '0px' }}>
                <Col lg={4} md={4} sm={4} xs={12} className="p-0 dineinimage-wrapper1">
                    <div className="dineinimage"></div>
                </Col>

                <Col lg={8} md={8} sm={8} xs={12} className="form1 align-items-center bgimage p-0">
                    <div className="content-wrapper w-100">
                        <div className="top1 d-flex justify-content-end align-items-end pt-0 p-4 mt-5 mb-0" style={{paddingTop:'50px'}}>
                            {/* <img src={logoimage} alt="Logo" className="logo-image" /> */}
                            <p className="mb-0">
                                Already a member? <a href="/signin">Sign Up</a>
                            </p>
                        </div>

                        <div className="form-container mx-auto" style={{ padding: '0 10%' }}>
                            <h2 className="signuph2 text-center">Request for Membership</h2>

                            <Form onSubmit={handleSubmit} noValidate>
                                <Row className="align-items-center justify-content-center">
                                    <Col lg={8} md={8} sm={8} xs={12}>
                                        <Form.Group controlId="name" className="mb-3">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Name"
                                                name="name"  // Add name attribute here
                                                value={formData.name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.name}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="align-items-center justify-content-center">
                                    <Col lg={8} md={8} sm={8} xs={12}>
                                        <Form.Group controlId="email" className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Email"
                                                name="email"  // Add name attribute here
                                                value={formData.email}
                                                onChange={handleChange}
                                                isInvalid={!!errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="align-items-center justify-content-center">
                                    <Col lg={8} sm={8} md={8} xs={12}>
                                        <Form.Group controlId="phoneNumber" className="mb-3">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="1234567890"
                                                name="phoneNumber"  // Add name attribute here
                                                value={formData.phoneNumber}
                                                onChange={handleChange}
                                                isInvalid={!!errors.phoneNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="align-items-center justify-content-center">
                                    <Col lg={8} sm={8} md={8} xs={12}>
                                        <Form.Group controlId="address" className="mb-3">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control
                                                as="textarea"  // Updated for textarea
                                                placeholder="Address"
                                                name="address"  // Add name attribute here
                                                value={formData.address}
                                                onChange={handleChange}
                                                isInvalid={!!errors.address}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="align-items-center justify-content-center">
                                    <Col lg={8} sm={8} md={8} xs={12}>
                                        <Form.Group controlId="addMember" className="otpbtn mb-3">
                                            <Button variant="warning" type="submit" className="w-100">
                                                Request For Membership
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
