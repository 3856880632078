import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logoimage from '../assets/DF-LOGO1 (2).png'; // Adjust the path as needed
import { FaTwitter, FaFacebook, FaLinkedin, FaArrowRight } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-dark text-white py-4 align-item-cenetr ">
      <Container fluid className="px-5">
        <Row className="align-items-center">
          <Col xs={12} md={3} className="mb-4 mb-md-0">
            {/* Logo Section */}
            <img 
              src={logoimage} 
              alt="Logo" 
              style={{ maxHeight: '70px', justifyContent:'center'}} // Adjust logo size if needed
            />
            {/* About Us Section */}
            <div className="mt-2">
              <h5>About Us</h5>
              <p style={{ color: '#FFFFFF', lineHeight: '1.6' }}>
                We are dedicated to providing you with the best services and offers. Our team works hard to bring you the latest and greatest in the industry.
              </p>
            </div>
            <div className="d-flex social-icons mt-3">
              <a href="#" className="text-light mx-2">
                <FaTwitter size={20} />
              </a>
              <a href="#" className="text-light mx-2">
                <FaFacebook size={20} />
              </a>
              <a href="#" className="text-light mx-2">
                <FaLinkedin size={20} />
              </a>
            </div>
          </Col>
          <Col xs={12} md={9} >
          <Row className="justify-content-center align-item-cenetr px-5">
            <Row className="justify-content-center  custom-padding" >
              <Col xs={12} md={3} className="mb-4 mb-md-0">
                <h5>Discover</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <a href="#" style={{ textDecoration: 'none', color: '#FFFFFF' }}>Bestsellers</a>
                  </li>
                  <li className="mb-2">
                    <a href="#" style={{ textDecoration: 'none', color: '#FFFFFF' }}>Shop all</a>
                  </li>
                  <li className="mb-2">
                    <a href="#" style={{ textDecoration: 'none', color: '#D9534F' }}>% Sales</a>
                  </li>
                </ul>
              </Col>
              <Col xs={12} md={3} className="mb-4 mb-md-0">
                <h5>About</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <a href="#" style={{ textDecoration: 'none', color: '#FFFFFF' }}>Help Center</a>
                  </li>
                  <li className="mb-2">
                    <a href="#" style={{ textDecoration: 'none', color: '#FFFFFF' }}>Shipping/Returns</a>
                  </li>
                  <li className="mb-2">
                    <a href="#" style={{ textDecoration: 'none', color: '#FFFFFF' }}>Affiliates</a>
                  </li>
                </ul>
              </Col>
              <Col xs={12} md={3} className="mb-4 mb-md-0">
                <h5>Legal</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <a href="#" style={{ textDecoration: 'none', color: '#FFFFFF' }}>Contact</a>
                  </li>
                  <li className="mb-2">
                    <a href="#" style={{ textDecoration: 'none', color: '#FFFFFF' }}>Privacy Policies</a>
                  </li>
                  <li className="mb-2">
                    <a href="#" style={{ textDecoration: 'none', color: '#FFFFFF' }}>Terms & Conditions</a>
                  </li>
                </ul>
              </Col>
            </Row>
            </Row>
          </Col>
        </Row>
        <Row className="mt-4 text-center" style={{borderTop:'1px solid white'}}>
          <Col>
            <small style={{ color: "white", }}>&copy; 2021 rspective</small>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
