import React from 'react';
import '../CSS/Coupon2.css';
import { Col, Container, Row } from 'react-bootstrap';
import backk from '../assets/Subtractlarge (1).png';
import feastimg from '../assets/Rectangle 307.png';
import Horizontal from '../assets/Vector 2.png';
import QRsacn from '../assets/qr_img 1.png';
import externallink from '../assets/external-link.png';
import info from '../assets/info.png';
import { ImCross } from "react-icons/im";

export default function Coupon2() {
  return (
    <>
      <Container className="coupon-container">
        <Row >
        <div className="close-icon">
          <ImCross className='cross' style={{ cursor: 'pointer' }} />
        </div>
        </Row>
        <div className="coupon-content">
          <Row className="align-items-center">
            <Col xs={4} >
              <img src={feastimg} alt="Coupon Feast" className="coupon-image" />
            </Col>
            <Col xs={8} className="coupon-text">
              <h3>15% OFF</h3>
              <h5>KFC</h5>
            </Col>
          </Row>
          <Row className="text-center mt-3">
            <Col>
              <h3 className="discount-text">15% off on total bill of ₹799</h3>
            </Col>
          </Row>
          <Row className="details-list">
            <Col >
              <ul>
                <li>Redeemable at all Dossai restaurants in Pune.</li>
                <li>Not valid with any other discounts or promotions.</li>
                <li>No cash value.</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <img src={Horizontal} alt="Horizontal Line" className="horizontal-line" />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center mt-3">
            <img src={QRsacn} alt="QR Scan" className="qr-image" />
          </Row>
          <Row className="align-items-center mt-3">
            <Col xs={2} className="text-center">
              <img src={externallink} alt="External Link" className="icon-image" />
            </Col>
            <Col xs={8}>
              <h5 className="info-text">Offer has been redeemed. Must be used at the restaurant within 24 hours.</h5>
            </Col>
            <Col xs={2} className="text-center">
              <img src={info} alt="Info" className="icon-image" />
            </Col>
          </Row>
        </div>
        {/* <div className="image-container">
          <img src={backk} alt="Background" className="background-image" />
        </div> */}
      </Container>
    </>
  );
}
