import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import logoimage from '../assets/DF-LOGO1 (2).png'; // Adjust the path as needed
import { FaShoppingCart } from 'react-icons/fa';

export default function Header() {
  const navigate = useNavigate();

  return (
    <Navbar expand="md" className="py-0 bg-dark px-5">
      <Container fluid>
        {/* Logo */}
        <Navbar.Brand href="/">
          <img src={logoimage} alt="Logo" style={{ maxHeight: '80px' }} />
        </Navbar.Brand>

        {/* Toggle button for mobile view */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="bg-light" />

        {/* Navbar links and buttons */}
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="me-4">
            <Nav.Link href="#" style={{ color: '#FFFFF3', fontWeight: '500' }}>Dine In</Nav.Link>
            <Nav.Link href="#" style={{ color: '#FFFFF3', fontWeight: '500' }}>Shopping</Nav.Link>
            <Nav.Link href="#" style={{ color: '#FFFFF3', fontWeight: '500' }}>Profile</Nav.Link>
            <Nav.Link href="#" style={{ color: '#FFFFF3', fontWeight: '500' }}>
              <FaShoppingCart />
            </Nav.Link>
          </Nav>

          {/* Auth Buttons */}
          <div className="d-flex align-items-center">
            <Button
              variant="secondary"
              className="mx-2"
              style={{
                fontSize: '14px',
                padding: '8px 15px',
                borderRadius: '20px',
                backgroundColor: '#EF4C0B',
                width: '100px',
                border:'none'
              }}
              onClick={() => navigate('/signin')}
            >
             Register
            </Button>
            <Button
              variant="secondary"
              className="mx-2"
              style={{
                fontSize: '14px',
                padding: '8px 15px',
                borderRadius: '20px',
                width: '100px',
              }}
              onClick={() => navigate('/signupform')}
            >
              Sign In
            </Button>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
