import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { auth } from '../firebase.config';
import { RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, signInWithCredential } from 'firebase/auth';
import axios from 'axios';
import '../CSS/SignUp.css';
import logoimage from '../assets/Background Removed - Discount Feast Logo.png';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

export default function SignUpform() {
  const [showForm, setShowForm] = useState(false);
  const [hideImage, setHideImage] = useState(false);
  const [countryCode, setCountryCode] = useState('+91'); 
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(''); 
  const [loading, setLoading] = useState(false);
  const [showImage, setShowImage] = useState(true); // New state to manage image visibility
  const recaptchaVerifierRef = useRef(null);
  const navigate = useNavigate();
  const otpInputRef = useRef(null);

  // Handle sending OTP
  const sendOtp = async () => {
    setLoading(true);
    setSuccess('');
    setError('');

    if (!recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response) => {
            console.log('reCAPTCHA solved', response);
          },
          'expired-callback': () => {
            console.log('reCAPTCHA expired');
          },
        },
        auth
      );
    }

    try {
      const appVerifier = recaptchaVerifierRef.current;
      const fullPhoneNumber = `${countryCode}${phoneNumber}`;
      const confirmationResult = await signInWithPhoneNumber(auth, fullPhoneNumber, appVerifier);
      setVerificationId(confirmationResult.verificationId);
      setOtpSent(true);
      setError('');
      setLoading(false);

      if (otpInputRef.current) {
        otpInputRef.current.focus();
      }
    } catch (err) {
      console.error('Error sending OTP:', err);
      setError('Failed to send OTP. Please try again.');
      setLoading(false);
      recaptchaVerifierRef.current.clear();
      recaptchaVerifierRef.current = null;
    }
  };

  // Verify phone number with backend
  const verifyPhoneNumberWithBackend = async () => {
    try {
      const response = await axios.post('https://discountfeast.co.in/newmember/verifylogin', { phoneNumber });
      if (response.status === 200) {
        setSuccess('Phone number verified successfully!');
        navigate('/offercards');
      } else {
        setError('Phone number not verified or not found.');
      }
    } catch (err) {
      setError('Error verifying phone number: ' + (err.response?.data?.message || err.message));
    }
  };

  // Handle OTP verification
  const verifyOtp = async () => {
    if (!otp || !verificationId) {
      setError('Please enter the OTP.');
      return;
    }

    setLoading(true);

    try {
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      await signInWithCredential(auth, credential);
      setSuccess('OTP Verified Successfully!');
      setError('');

      await verifyPhoneNumberWithBackend();
    } catch (err) {
      console.error('Error verifying OTP:', err);
      setError('Invalid OTP. Please enter the correct OTP.');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (phoneNumber.length === 10 && !otpSent) {
      sendOtp();
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (otp.length === 6 && verificationId) {
      verifyOtp();
    }
  }, [otp]);

  return (
    <>
      <Container fluid className="align-items-center justify-content-center p-0 w-100" style={{ maxWidth: '100%' }}>
        <Row className="w-100" style={{ marginLeft: '0px' }}>
              <Col lg={4} md={4} sm={4} xs={12} className='p-0 dineinimage-wrapper1 '>
                <div className="dineinimage"></div>
              </Col>
              <Col lg={8} md={8} sm={8} xs={12} className='form1 align-items-center bgimage p-3' >
                <div className="content-wrapper w-100">
                  <div className="top1 d-flex justify-content-between align-items-center p-3">
                    <img src={logoimage} alt="Logo" className="logo-image" style={{maxWidth:'50%'}}/>
                  </div>

                  <div className="signinfrm form-container mx-auto" style={{ padding: '0 10%' }}>
                    <h2 className="signuph2 text-center">Sign In to Discount Feast</h2>

                    <Form className="d-grid align-items-center">
                      <Row className="justify-content-center">
                        <Col lg={8} sm={12} md={12} xs={12}>
                          <Form.Group controlId="PhoneNumber" className="mb-3">
                            <Form.Label className="forminput">Phone Number</Form.Label>
                            <div className="d-flex">
                              <Form.Control
                                className="inputfield"
                                type="text"
                                placeholder="Enter Phone Number"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                required
                              />
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="justify-content-center">
                        <Col lg={8} sm={12} md={12} xs={12}>
                          <Form.Group controlId="otp" className="mb-3">
                            <Form.Label className="forminput">OTP</Form.Label>
                            <Form.Control
                              className="inputfield"
                              type="text"
                              placeholder="6 characters"
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                              required
                              style={{ width: '70%' }}
                              ref={otpInputRef}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      {error && <p className="text-danger mt-2">{error}</p>}
                      {success && <p className="text-success mt-2">{success}</p>}

                      <Row style={{ padding: '0 30px' }} >
                        <Col lg={5} md={10} sm={10} xs={10} className="d-flex justify-content-center mb-2">
                          <Button variant="warning" style={{ width: '50%' }} onClick={verifyOtp} disabled={loading}>
                            {loading ? 'Processing...' : 'Sign In'}
                          </Button>
                        </Col>
                        <Col lg={5} md={10} sm={10} xs={10} className="d-flex justify-content-center p-0 mb-2">
                          <Button variant="warning" style={{ width: '50%' }} onClick={() => navigate('/pay')}>
                            Pay Now
                          </Button>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className="text-center mt-2">
                          Not a member yet? <a href="/signin">Sign up</a>
                        </Col>
                      </Row>

                      <Col lg={12} className="mt-2 text-center">
                        <small>
                          This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.
                        </small>
                        <div id="recaptcha-container"></div>
                      </Col>
                    </Form>
                  </div>
                </div>
              </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
