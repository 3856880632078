import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Coupon1 from './Components/Coupon1';
import Coupon2 from './Components/Coupon2';
import Pay from './Components/Pay';
import Offercards from './Components/Offercards';
import SignUp from './Components/SignUp';
import SignUpform from './Components/SignupFrm';
import RequestForMembership from './Components/RequestForMembership';
import Header from './Components/Header';

export default function App() {
  return (
    <Router>
      <Routes>
      <Route path='/coupon1' element={<Coupon1/>} />
      <Route path='/coupon2' element={<Coupon2/>} />
      <Route path='/pay' element={<Pay/>} />
      <Route path='/' element={<Offercards/>} />
      <Route path='/signin' element={<SignUp/>} />
      <Route path='/signupform' element={<SignUpform/>} />
      <Route path='/requestForMemberShip' element={<RequestForMembership/>}/>
      <Route path='/header' element={<Header/>}/>
      </Routes>
    </Router>
  );
}
