// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDg7p5C2f_7d8OdXxDv2MvyPGK4qAOvEpM",
  authDomain: "discountfeast-8564e.firebaseapp.com",
  projectId: "discountfeast-8564e",
  storageBucket: "discountfeast-8564e.appspot.com",
  messagingSenderId: "834263402763",
  appId: "1:834263402763:web:15b97a365eed3325083a0e",
  measurementId: "G-QVVLXTEQTZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Export the authentication and Recaptcha functions
export { auth, RecaptchaVerifier, signInWithPhoneNumber };

export default app;
