import React, { useState } from 'react';
import { Col, Container, Row, Button, Modal } from 'react-bootstrap';
import couponbox from '../assets/Subtract.png';
import Couponfeast from  '../assets/Rectangle 306.png';

import '../CSS/Coupon1.css';  // Media queries are added here

export default function Coupon1() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Container fluid className="text-center">  {/* Container-fluid for full-width responsiveness */}
        {/* Redeem Button */}
        <Button 
          onClick={handleShow} 
          style={{
            backgroundColor: 'white',
            color: '#FF0000',
            border: '2px solid black',
            borderRadius: '10px', 
            padding: '10px 20px',
            fontWeight: 'bold',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
          }}
        >
          Redeem
        </Button>

        {/* Coupon Modal */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Coupon Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{
              border: '2px solid black',
              padding: '10px',  
              borderRadius: '20px',  
              position: 'relative',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            }}>
              <Row className="align-items-center">
                <Col xs={4} className="text-center">
                  <img 
                    src={Couponfeast} 
                    alt="Coupon Feast" 
                    style={{ maxWidth: '90%', borderRadius: '10px' }} 
                  />
                </Col>
                <Col xs={8} style={{ padding: '10px' }}>
                  <h3 style={{ color: '#FF0000', fontWeight: 'bold' }}>10% OFF</h3>
                  <h6>10% off on total bill of ₹499</h6>
                </Col>
              </Row>
             
              <img 
                src={couponbox} 
                alt="Coupon Box" 
                style={{ 
                  marginTop: '-70px',  
                  zIndex: '-1', 
                  position: 'relative', 
                  maxWidth: '100%', 
                  borderRadius: '20px'  
                }} 
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}
